








































import { Component, Vue } from 'vue-property-decorator'
import RouteInfos, { RouteInfo } from '@/router/route_info'
import ApiManager from '../net/api-manager'

@Component
export default class Footer extends Vue {
  selectedUrl = '/search'
  existsFavorite = false

  get routeName(): string {
    return this.$route.name ? this.$route.name : ''
  }

  get routeInfo(): RouteInfo {
    return RouteInfos[this.routeName]
  }

  async mounted(): Promise<void> {
    // ログイン画面など、フッターを使用しない画面ではAPIを実行しない
    if (this.routeInfo.footerType !== 'noShow') {
      const res = await ApiManager.getFavorites(1)
      this.existsFavorite = res.length > 0
    }
  }

  isShowBadge(iconName: string): boolean {
    return iconName === 'FavoriteReceipt' && this.existsFavorite
  }

  isActive(name: string): boolean {
    const currentName = this.routeName
    return currentName === name
  }

  goto(url: string) {
    this.selectedUrl = url
    this.$router.push(url)
  }

  icons = [
    {
      activeImage: require('@/assets/images/icons/icon_footer_search_active.svg'),
      inactiveImage: require('@/assets/images/icons/icon_footer_search_inactive.svg'),
      word: '検索',
      name: 'SearchPage',
      goTo: '/search',
    },
    {
      activeImage: require('@/assets/images/icons/icon_footer_favorite_active.svg'),
      inactiveImage: require('@/assets/images/icons/icon_footer_favorite_inactive.svg'),
      word: 'いいね',
      name: 'FavoriteReceipt',
      goTo: '/favorit-receipt',
    },
    {
      activeImage: require('@/assets/images/icons/icon_footer_message_active.svg'),
      inactiveImage: require('@/assets/images/icons/icon_footer_message_inactive.svg'),
      word: 'メッセージ',
      name: 'MessageThreadList',
      goTo: '/message-thread',
    },
    {
      activeImage: require('@/assets/images/icons/icon_footer_mypage_active.svg'),
      inactiveImage: require('@/assets/images/icons/icon_footer_mypage_inactive.svg'),
      word: 'マイページ',
      name: 'MyPage',
      goTo: '/my-page',
    },
  ]
}
