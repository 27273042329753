enum STYLE_TYPE {
  NONE = 'NONE',
  スレンダー = 'スレンダー',
  普通 = '普通',
  グラマー = 'グラマー',
  筋肉質 = '筋肉質',
  ややぽっちゃり = 'ややぽっちゃり',
  ぽっちゃり = 'ぽっちゃり',
}

export default STYLE_TYPE
