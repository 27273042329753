













































import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class VisitHistory extends Vue {
  get deividedItems() {
    return this.items
      .map((item) => [item, { divider: true, inset: true }])
      .flat()
  }

  items = [
    {
      avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
      title: 'まるまいん',
      subTitle: '50歳・千葉県・経営者',
      description: '初めて使います、よろしくね',
    },
    {
      avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
      title: 'まるまいん',
      subTitle: '50歳・千葉県・経営者',
      description: '初めて使います、よろしくね',
    },
    {
      avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
      title: 'まるまいん',
      subTitle: '50歳・千葉県・経営者',
      description: '初めて使います、よろしくね',
    },
    {
      avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
      title: 'まるまいん',
      subTitle: '50歳・千葉県・経営者',
      description: '初めて使います、よろしくね',
    },
    {
      avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
      title: 'まるまいん',
      subTitle: '50歳・千葉県・経営者',
      description: '初めて使います、よろしくね',
    },
  ]
}
