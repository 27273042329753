





































































import { Component, Vue } from 'vue-property-decorator'
import ApiManager from '../net/api-manager'
import Message from '../net/sync/message'
import MessageThread from '../net/sync/message_thread'
import User from '../net/sync/user'
import MessageType from '../net/sync/types/message_type'
import SendingImagePreviewDialog from '../components/SendingImagePreviewDialog.vue'
import ImagePreviewDialog from '../components/ImagePreviewDialog.vue'
import PaymentDemandDialog from '../components/PaymentDemandDialog.vue'
import RequestPostImageFileOnMessage from '../net/sync/request/request_post_image_file_on_message'
import firebase from 'firebase'
import { Mutation } from 'vuex-class'
import * as types from '../store/mutation_types'
import InfiniteLoading from 'vue-infinite-loading'
import ChatCard from '../components/ChatCard.vue'
import { HeaderUser } from '@/store/state'
import AvatorImageUtil from '../util/avator_image_util'
import store from '@/store/store'
import DownloadImageUtil from '../util/download_image_util'
import imageCompression from 'browser-image-compression'

@Component({
  components: {
    SendingImagePreviewDialog,
    ImagePreviewDialog,
    PaymentDemandDialog,
    InfiniteLoading,
    ChatCard,
  },
})
export default class MessageThreadPage extends Vue {
  messageThreadId!: string
  messageThread!: MessageThread
  canUsePaidFeature = false

  messages: Message[] = []
  isScreenCreated = false // moutedの後にtrueにする
  containsLastMessage = false
  myAvatarImageUrl = ''
  partnerAvatarImageUrl = ''

  myUser!: User
  partnerUser!: User

  // 課金を促すダイアログ
  isShowPaymentDialog = false

  // 送信する画像を確認するためのダイアログ
  isShowSendingImagePreviewDialog = false
  uploadImageDataUrl = ''

  // チャット上に表示されている画像を拡大表示するダイアログ
  isShowImagePreviewDialog = false
  previewImageUrl = ''
  sendingMessage = ''

  @Mutation(types.setHeaderUserInfo) setHeaderUserInfo!: (
    user: HeaderUser
  ) => void

  get refs(): any {
    return this.$refs
  }

  get vuetifyModule(): any {
    return this.$vuetify
  }

  get pageHeight(): number {
    return document.body.scrollHeight
  }

  get appearFooter(): boolean {
    return this.canUsePaidFeature
  }

  get isShowLoadButton(): boolean {
    return !this.containsLastMessage && this.isScreenCreated
  }

  appearDate(index: number): string {
    const sendDateStr = new Date(
      this.messages[index].sendDate
    ).toLocaleDateString()
    if (index === 0) {
      return sendDateStr
    }

    const beforeSendDate = new Date(
      this.messages[index - 1].sendDate
    ).toLocaleDateString()
    if (sendDateStr === beforeSendDate) {
      return ''
    }
    return sendDateStr
  }

  isMessageOnlyText(message: Message): boolean {
    return message.messageType === MessageType.ONLY_MESSAGE
  }

  isMe(message: Message): boolean {
    return message.fromUserId == this.myUser.id
  }

  getIconImageUrl(message: Message): string {
    return this.isMe(message)
      ? this.myAvatarImageUrl
      : this.partnerAvatarImageUrl
  }

  getUserToSendMessage(message: Message): User {
    return this.isMe(message) ? this.myUser : this.partnerUser
  }

  getIconName(message: Message): string {
    return this.isMe(message) ? this.myUser.name : this.partnerUser.name
  }

  clearMessage(): void {
    this.sendingMessage = ''
  }

  onClickSendImageButton(): void {
    this.refs.inputImage.click()
  }

  // TODO:画像が表示できない場合のハンドリング
  async previewImage(uploadedStorageFilePath: string): Promise<void> {
    const filePath = await DownloadImageUtil.getImageUrl(
      uploadedStorageFilePath
    )

    this.previewImageUrl = filePath
    this.isShowImagePreviewDialog = true
  }

  async selectedFile(): Promise<void> {
    const file = this.refs.inputImage.files[0] as File
    if (!file) {
      return
    }
    const fr = new FileReader()
    fr.readAsDataURL(file)
    fr.addEventListener('load', () => {
      this.uploadImageDataUrl = fr.result as string
      this.isShowSendingImagePreviewDialog = true
    })
  }

  async beforeCreate(): Promise<void> {
    const paymentUser = await ApiManager.getPaymentUser()
    this.canUsePaidFeature = paymentUser.isPaid || paymentUser.isFree
    this.isShowPaymentDialog = !this.canUsePaidFeature
  }

  created(): void {
    this.messageThreadId = this.$route.params.message_thread_id
  }

  async mounted(): Promise<void> {
    store.commit(types.showIndicator)

    this.messageThread = await ApiManager.getMessageThread(this.messageThreadId)

    const myUserId = firebase.auth().currentUser?.uid

    // TODO: メッセージスレッドからUserオブジェクト拾ってくればわざわざAPI叩かなくていい
    const manUser = await ApiManager.getUser(this.messageThread.manUserId)
    const womanUser = await ApiManager.getUser(this.messageThread.womanUserId)

    if (manUser.id === myUserId) {
      this.myUser = manUser
      this.partnerUser = womanUser
    } else {
      this.myUser = womanUser
      this.partnerUser = manUser
    }

    this.myAvatarImageUrl = await AvatorImageUtil.getAvatorImageUrl(
      this.myUser.sexType,
      this.myUser.avatarImageUrl
    )

    this.partnerAvatarImageUrl = await AvatorImageUtil.getAvatorImageUrl(
      this.partnerUser.sexType,
      this.partnerUser.avatarImageUrl
    )

    this.setHeaderUserInfo({
      name: this.partnerUser.name,
      sexType: this.partnerUser.sexType,
      avatarImageUrl: this.partnerAvatarImageUrl,
    })

    await this.loadMessages()

    await this.scrollToLatestMessage()

    this.isScreenCreated = true
    store.commit(types.closeIndicator)
  }

  scrollToLatestMessage(): void {
    this.vuetifyModule.goTo(this.pageHeight, {
      duration: 0,
      easings: 'linear',
    })
  }

  async loadNextOldestMessages(): Promise<void> {
    store.commit(types.showIndicator)
    // 一番古いsendDateを取り出す、
    const oldestSendDateTime = this.containsLastMessage
      ? undefined
      : this.messages.reduce((a, b) => (a.sendDate < b.sendDate ? a : b))
          .sendDate

    await this.loadMessages(oldestSendDateTime)
    store.commit(types.closeIndicator)
  }

  async loadMessages(
    oldestSendDateTime: Date | undefined = undefined
  ): Promise<void> {
    store.commit(types.showIndicator)
    const response = await ApiManager.getMessages(
      this.messageThreadId,
      oldestSendDateTime
    )

    const newMessages = response.messages
    this.containsLastMessage = response.is_last

    // 重複メッセージがある場合は表示しないで追記する
    newMessages.forEach((newMessage) =>
      this.messages.some((m) => m.id === newMessage.id)
        ? null
        : this.messages.push(newMessage)
    )

    this.messages.sort((a, b) => {
      if (a.sendDate > b.sendDate) {
        return 1
      } else if (a.sendDate < b.sendDate) {
        return -1
      } else {
        return 0
      }
    })

    const unreadedMessages = this.messages.filter((message) => !message.isRead)
    // TODO: 未読のメッセージがあった場合、表示中の最新の未読メッセージまでを既読にする
    if (unreadedMessages.length > 0) {
      await ApiManager.postMessageHasRead(this.messageThreadId)
    }
    store.commit(types.closeIndicator)
  }

  async sendMessage(): Promise<void> {
    if (this.sendingMessage === '') {
      return
    }

    store.commit(types.showIndicator)
    const message = new Message({
      messageThreadId: this.messageThreadId,
      fromUserId: this.myUser.id,
      sendDate: new Date(),
      messageType: MessageType.ONLY_MESSAGE,
      isRead: false,
      message: this.sendingMessage,
    })

    await ApiManager.postMessage(message)
    this.clearMessage()
    await this.loadMessages()
    this.scrollToLatestMessage()
    store.commit(types.closeIndicator)
  }

  // 画像ファイルを取得
  async getCompressImageFileAsync(file: any) {
    const options = {
      maxSizeMB: 1, // 最大ファイルサイズ
      maxWidthOrHeight: 1024, // max-width
    }
    try {
      return await imageCompression(file, options)
    } catch (error) {
      console.error('getCompressImageFileAsync is error', error)
      throw error
    }
  }

  async sendImage(): Promise<void> {
    store.commit(types.showIndicator)
    const req = new RequestPostImageFileOnMessage(
      this.myUser.id,
      this.partnerUser.id
    )
    const imageBlob = await fetch(this.uploadImageDataUrl).then((file) =>
      file.blob()
    )
    const compFile = await this.getCompressImageFileAsync(imageBlob)
    const response = await ApiManager.postImage(
      this.messageThreadId,
      compFile as File,
      req
    )

    // FIXME: アラート表示は暫定対応（ハンドリング場所も）
    if (response.error) {
      switch (response.error.code) {
        case '413':
          store.commit(
            types.showErrorDialog,
            'アップロードする画像のサイズが大きすぎます'
          )
          break
        default:
          store.commit(types.showErrorDialog, '予期せぬエラーが発生しました')
          break
      }
      return
    }

    this.isShowSendingImagePreviewDialog = false
    await this.loadMessages()
    store.commit(types.closeIndicator)
  }
}
