




























































import { Component, Vue } from 'vue-property-decorator'
import apiManager from '@/net/api-manager'
import ImageCropperDialog from '@/components/organisms/ImageCropperDialog.vue'
import AccountRegisterImageSkipConfirmDialog from '@/components/organisms/AccountRegisterImageSkipConfirmDialog.vue'
import User from '@/net/sync/user'
import SexType from '@/net/sync/types/sex_type'
import store from '@/store/store'
import * as types from '@/store/mutation_types'

@Component({
  components: {
    AccountRegisterImageSkipConfirmDialog,
    ImageCropperDialog,
  },
})
export default class AccountRegisterImageUploadPage extends Vue {
  uploadImageDataUrl = ''
  croppedImage = ''
  isShowCropperDialog = false
  isShowDialog = false
  initialized = false
  user: User | undefined = undefined

  defaultManImage = require('@/assets/images/man_no_image.jpg')
  defaultWomanImage = require('@/assets/images/woman_no_image.jpg')

  get isSelectedImage(): boolean {
    return this.uploadImageDataUrl !== ''
  }

  get defaultImageUrl(): string {
    return this.user?.sexType === SexType.Man
      ? this.defaultManImage
      : this.defaultWomanImage
  }

  // TODO: any直す
  get refs(): any {
    return this.$refs
  }

  async created(): Promise<void> {
    store.commit(types.showIndicator)
    this.user = await apiManager.getMe()
    this.initialized = true
    store.commit(types.closeIndicator)
  }

  onClickSelectImage(): void {
    this.refs.inputImage.click()
  }

  async selectedFile(): Promise<void> {
    const file = this.refs.inputImage.files[0] as File
    if (!file) {
      return
    }
    const fr = new FileReader()
    fr.readAsDataURL(file)
    fr.addEventListener('load', () => {
      this.uploadImageDataUrl = fr.result as string
      this.isShowCropperDialog = true
    })
  }

  async onCroppedImage(image: string): Promise<void> {
    store.commit(types.showIndicator)
    this.croppedImage = await this.blurImage(image)
    store.commit(types.closeIndicator)
  }

  async blurImage(image: string): Promise<string> {
    store.commit(types.showIndicator)
    const imageBlob = await fetch(image).then((file) => file.blob())
    const a = await apiManager.postTransformBlur(imageBlob as File)
    const blob = new Blob([a])
    store.commit(types.closeIndicator)
    return window.URL.createObjectURL(blob)
  }

  async sendImage(): Promise<void> {
    store.commit(types.showIndicator)
    if (this.croppedImage === '') {
      return
    }
    const imageBlob = await fetch(this.croppedImage).then((file) => file.blob())
    try {
      const response = await apiManager.postUserMainImage(imageBlob as File)
      this.$router.push('/search')
      store.commit(types.closeIndicator)
    } catch (e) {
      // FIXME: アラート表示は暫定対応（ハンドリング場所も）
      store.commit(types.closeIndicator)
      switch (e.code) {
        case '413':
          store.commit(
            types.showErrorDialog,
            'アップロードする画像のサイズが大きすぎます'
          )
          break
        default:
          store.commit(types.showErrorDialog, '予期せぬエラーが発生しました')
          break
      }
    }
  }
}
