





















import { Component, Vue, PropSync } from 'vue-property-decorator'

@Component
export default class AccountRegisterImageSkipConfirmDialog extends Vue {
  @PropSync('isShowDialog', { type: Boolean, default: false })
  isShow!: boolean
}
