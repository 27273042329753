
















import { Component, Vue } from 'vue-property-decorator'
import Footer from './components/Footer.vue'
import Header from './components/Header.vue'
import ErrorDialog from './components/organisms/ErrorDialog.vue'
import Indicator from './components/organisms/Indicator.vue'
import InformationDialog from './components/organisms/InformationDialog.vue'

@Component({
  components: {
    Footer,
    Header,
    ErrorDialog,
    Indicator,
    InformationDialog,
  },
})
export default class App extends Vue {}
