














































































































































import apiManager from '@/net/api-manager'
import PaymentUser from '@/net/sync/payment_users'
import { Component, Vue } from 'vue-property-decorator'
import * as types from '@/store/mutation_types'
import store from '@/store/store'

interface PaymentPlan {
  param?: string
  imagePath?: string
  rebillParamId?: string
  money?: string
  planName: string
  totalFee: number
  feePerMonth: number
  display?: string
}

@Component({
  components: {},
})
export default class Payment extends Vue {
  sendId = ''
  isPaidUser = false
  isFreeUser = false
  mailAddress = ''
  usrtel = ''
  clientip = process.env.VUE_APP_TELECOM_CREDIT_CLIENT_IP ?? ''
  sendpass = ''
  isDebugging = process.env.NODE_ENV != 'production'

  paymentUser!: PaymentUser

  paymentPlans: PaymentPlan[] = this.isDebugging
    ? [
        {
          imagePath: require('../assets/images/plan_button_half_year.svg'),
          money: '5000',
          rebillParamId: '30day5000yen',
          planName: '３０日３０００円プラン',
          totalFee: 3000,
          feePerMonth: 3000,
          display: '5000yen plan',
        },
        {
          imagePath: require('../assets/images/plan_button_three_month.svg'),
          money: '3000',
          rebillParamId: '30day3000yen',
          planName: '３０日５０００円プラン',
          totalFee: 5000,
          feePerMonth: 5000,
          display: '3000yen plan',
        },
        {
          imagePath: require('../assets/images/plan_button_a_month.svg'),
          money: '200',
          rebillParamId: '1day200yen',
          planName: 'テスト用１日２００円プラン',
          totalFee: 200,
          feePerMonth: 60000,
          display: '200yen plan',
        },
        {
          imagePath: require('../assets/images/plan_button_a_month.svg'),
          money: '200',
          rebillParamId: '2day200yen',
          planName: 'テスト用２日２００円プラン',
          totalFee: 200,
          feePerMonth: 30000,
          display: '200yen plan',
        },
      ]
    : [
        {
          imagePath: require('../assets/images/plan_button_half_year.svg'),
          money: '26880',
          rebillParamId: '180day26880yen',
          planName: '１８０日２６８８０円プラン',
          totalFee: 26880,
          feePerMonth: 9000,
          display: '26880yen plan',
        },
        {
          imagePath: require('../assets/images/plan_button_three_month.svg'),
          money: '14940',
          rebillParamId: '90day14940yen',
          planName: '９０日１４９４０円プラン',
          totalFee: 14940,
          feePerMonth: 3000,
          display: '14940yen plan',
        },
        {
          imagePath: require('../assets/images/plan_button_a_month.svg'),
          money: '5980',
          rebillParamId: '30day5980yen',
          planName: '３０日５９８０円プラン',
          totalFee: 5980,
          feePerMonth: 0,
          display: '5980yen plan',
        },
      ]

  getCompletedPath = location.origin + '/my-page'

  get refs(): any {
    return this.$refs
  }

  get paymentPlanName(): string {
    return `${this.getPaymentPlan()?.planName ?? '-'}`
  }

  get totalFee(): string {
    return `￥${this.getPaymentPlan()?.totalFee ?? '-'}`
  }

  get feePerMonth(): string {
    return `1か月 ￥${this.getPaymentPlan()?.feePerMonth ?? '-'}`
  }

  getPaymentPlan(): PaymentPlan | undefined {
    return this.paymentPlans.find(
      (x) => x.rebillParamId == this.paymentUser.paymentPlanType
    )
  }

  async mounted(): Promise<void> {
    store.commit(types.showIndicator)
    this.paymentUser = await apiManager.getPaymentUser()
    const user = await apiManager.getMe()
    this.sendId = this.paymentUser.id
    this.sendpass = this.paymentUser.sendpass
    this.mailAddress = user.email
    this.isPaidUser = this.paymentUser.isPaid
    this.isFreeUser = this.paymentUser.isFree
    store.commit(types.closeIndicator)
  }

  requestPayment(param: PaymentPlan): void {
    this.refs.paymentRequestFormInputRebillParamId.value = param.rebillParamId!
    this.refs.paymentRequestFormInputMoney.value = param.money!

    this.refs.paymentRequestFormSubmitButton.click()
  }

  async leavePayment(): Promise<void> {
    store.commit(types.showIndicator)
    await apiManager.leavePayment()
    store.commit(types.showInformationDialog, '支払いを停止しました')
    store.commit(types.closeIndicator)
  }
}
