









































import RouteInfos from '@/router/route_info'
import { Component, PropSync, Vue } from 'vue-property-decorator'

@Component
export default class PaymentDemandDialog extends Vue {
  @PropSync('isShowDialog', { type: Boolean, default: false })
  showStatus!: boolean

  goToPayment() {
    this.$router.push(RouteInfos.Payment.path)
  }
}
