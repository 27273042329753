import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#66CCCC',
      },
      dark: {
        primary: '#66CCCC',
      },
    },
  },
})
