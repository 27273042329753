























import { Component, Vue, Prop, PropSync, Emit } from 'vue-property-decorator'

@Component
export default class SendingImagePreviewDialog extends Vue {
  @PropSync('isShowDialog', { type: Boolean, default: false })
  showStatus!: boolean

  @Prop()
  uploadImageDataUrl!: string

  @Prop()
  messageThreadId!: string

  @Emit('sendImage')
  sendImageAction(): void {
    return
  }
}
