import firebase from 'firebase'

class DownloadImageUtil {
  async getImageUrl(url?: string): Promise<string> {
    if (!url) return ''
    try {
      const storageRef = firebase.storage().ref().child(url)
      const firebaseUrl = (await storageRef.getDownloadURL()) as string
      return firebaseUrl
    } catch (e) {
      return ''
    }
  }
}

export default new DownloadImageUtil()
