export interface RouteInfo {
  name: string
  title: string
  path: string
  headerType: HeaderType
  isShowBackButton: boolean
  footerType: FooterType
}

type HeaderType = 'noShow' | 'search' | 'title' | 'userName'
type FooterType = 'noShow' | 'common'
interface IRouteInfos {
  [index: string]: RouteInfo
}

const RouteInfos: IRouteInfos = {
  // TODO：仮LP LPできたら消す
  LandingPage: {
    name: 'LandingPage',
    title: 'LP',
    path: '/lp',
    headerType: 'noShow',
    isShowBackButton: false,
    footerType: 'noShow',
  },
  Login: {
    name: 'Login',
    title: 'ログイン',
    path: '/login',
    headerType: 'noShow',
    isShowBackButton: false,
    footerType: 'noShow',
  },
  MessageThreadList: {
    name: 'MessageThreadList',
    title: 'メッセージ一覧',
    path: '/message-thread',
    headerType: 'title',
    isShowBackButton: false,
    footerType: 'common',
  },
  MessageThread: {
    name: 'MessageThread',
    title: 'メッセージスレッド',
    path: '/message-thread/:message_thread_id',
    headerType: 'userName',
    isShowBackButton: true,
    footerType: 'noShow',
  },
  SearchPage: {
    name: 'SearchPage',
    title: '探す',
    path: '/search',
    headerType: 'search',
    isShowBackButton: false,
    footerType: 'common',
  },
  OtherProfilePage: {
    name: 'OtherProfilePage',
    title: '相手のプロフィール（相手の名前出す）',
    path: '/profile/other/:user_id',
    headerType: 'userName',
    isShowBackButton: true,
    footerType: 'common',
  },
  FavoriteReceipt: {
    name: 'FavoriteReceipt',
    title: '相手からのいいね',
    path: '/favorit-receipt',
    headerType: 'title',
    isShowBackButton: false,
    footerType: 'common',
  },
  AccountRegister: {
    name: 'AccountRegister',
    title: 'アカウント登録',
    path: '/account-register/:user_invitation_id/profile',
    headerType: 'noShow',
    isShowBackButton: false,
    footerType: 'noShow',
  },
  AccountRegisterImageUploadPage: {
    name: 'AccountRegisterImageUploadPage',
    title: '画像登録',
    path: '/account-register/:user_invitation_id/image-upload',
    headerType: 'noShow',
    isShowBackButton: false,
    footerType: 'noShow',
  },
  MyPage: {
    name: 'MyPage',
    title: 'マイページ',
    path: '/my-page',
    headerType: 'title',
    isShowBackButton: false,
    footerType: 'common',
  },
  VisitHistory: {
    name: 'VisitHistory',
    title: '足跡ページ',
    path: '/visit-history',
    headerType: 'title',
    isShowBackButton: false,
    footerType: 'common',
  },
  Payment: {
    name: 'Payment',
    title: '有料会員プラン',
    path: '/payment',
    headerType: 'title',
    isShowBackButton: false,
    footerType: 'common',
  },
  UserCertification: {
    name: 'UserCertification',
    title: '年齢確認・本人確認',
    path: '/user-certification',
    headerType: 'title',
    isShowBackButton: false,
    footerType: 'common',
  },
  EditProfile: {
    name: 'EditProfile',
    title: 'プロフィール編集',
    path: '/my-page/edit-profile',
    headerType: 'title',
    isShowBackButton: true,
    footerType: 'common',
  },
  EditMyProfileImagePage: {
    name: 'EditMyProfileImagePage',
    title: 'プロフィール写真を編集',
    path: '/my-page/edit-profile/image',
    headerType: 'title',
    isShowBackButton: true,
    footerType: 'common',
  },
  ChangEmail: {
    name: 'ChangEmail',
    title: 'メールアドレス変更',
    path: '/change-email',
    headerType: 'title',
    isShowBackButton: true,
    footerType: 'common',
  },
  ResetPassword: {
    name: 'ResetPassword',
    title: 'パスワードリセット',
    path: '/reset-password',
    headerType: 'title',
    isShowBackButton: false,
    footerType: 'noShow',
  },
  NotificationSetting: {
    name: 'NotificationSetting',
    title: '通知設定',
    path: '/notification-setting',
    headerType: 'title',
    isShowBackButton: true,
    footerType: 'common',
  },
}

export default RouteInfos
