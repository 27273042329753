

































import RequestUserSearchCondition from '@/net/sync/request/request_user_search_condition'
import User from '@/net/sync/user'
import { Component, Vue } from 'vue-property-decorator'
import { Mutation, State } from 'vuex-class'
import SearchDialog from '../components/SearchDialog.vue'
import ApiManager from '../net/api-manager'
import InfiniteLoading from 'vue-infinite-loading'
import SexType from '@/net/sync/types/sex_type'
import DownloadImageUtil from '../util/download_image_util'

interface UserInfo {
  user: User
  userImageUrl: string
}

@Component({
  components: {
    SearchDialog,
    InfiniteLoading,
  },
})
export default class SearchPage extends Vue {
  defaultManImage = require('@/assets/images/man_no_image.jpg')
  defaultWomanImage = require('@/assets/images/woman_no_image.jpg')

  @Mutation('setShowSearchDialog') setShowSearchDialog!: (
    showSearchDialog: boolean
  ) => void

  @State('searchCondition')
  private readonly searchCondition!: RequestUserSearchCondition

  users: Array<UserInfo> = []
  infiniteId = +new Date()
  condition = { page: 1 }

  created(): void {
    this.setShowSearchDialog(false)
    this.condition = this.searchCondition
    this.condition.page = 1
  }

  getImage(user: UserInfo) {
    if (user.userImageUrl) {
      return user.userImageUrl
    } else if (user.user.sexType === SexType.Man) {
      return this.defaultManImage
    } else {
      return this.defaultWomanImage
    }
  }

  search(condition: RequestUserSearchCondition): void {
    this.users = []
    this.condition = condition
    this.condition.page = 1
    this.infiniteId += 1
    this.setShowSearchDialog(false)
  }

  clear(): void {
    this.users = []
    this.condition = { page: 1 }
    this.infiniteId += 1
    this.setShowSearchDialog(false)
  }

  async infiniteHandler($state: any) {
    const res = await ApiManager.searchUser(this.condition)
    if (res.length) {
      const userInfos = await Promise.all(
        res.map(async (u) => {
          if (!u.userProfileImages) return { user: u, userImageUrl: '' }
          const mainImage = u.userProfileImages.find((e) => e.isMain)
          if (!mainImage) return { user: u, userImageUrl: '' }
          const url = await DownloadImageUtil.getImageUrl(mainImage.imageUrl)
          return { user: u, userImageUrl: url }
        })
      )
      this.condition.page += 1
      this.users.push(...userInfos)
      $state.loaded()
    } else {
      $state.complete()
    }
  }

  goToOtherProfilePage(userId: string) {
    this.$router.push(`profile/other/${userId}`)
  }
}
