import RequestUserSearchCondition from '@/net/sync/request/request_user_search_condition'
import SexType from '@/net/sync/types/sex_type'

export interface State {
  showSearchDialog: boolean
  searchCondition: RequestUserSearchCondition
  headerUserInfo: HeaderUser | undefined
  isShowErrorDialog: boolean
  isShowIndicator: boolean
  isShowInformationDialog: boolean
  informationMessage: string
  errorContext: ErrorContext | undefined
}

export const state = {
  showSearchDialog: false,
  searchCondition: {
    areaType: undefined,
    ageFrom: undefined,
    ageTo: undefined,
    heightFrom: undefined,
    heightTo: undefined,
    styleType: undefined,
    drinkFrequencyType: undefined,
    smokingFrequencyType: undefined,
    page: 0,
  },
  headerUserInfo: undefined,
  isShowErrorDialog: false,
  isShowIndicator: false,
  isShowInformationDialog: false,
  informationMessage: '',
  errorContext: undefined,
} as State

export interface ErrorContext {
  title: string
  message: string
}

export interface HeaderUser {
  name: string
  sexType: SexType
  avatarImageUrl: string | undefined // ※FirebaseのURLに変換済みのものを格納する
}
