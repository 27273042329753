



























import { Component, Vue } from 'vue-property-decorator'
import apiManager from '../net/api-manager'

// TODO: LPできたら消す
@Component({
  components: {},
})
export default class LP extends Vue {
  email = ''

  async sendMail(): Promise<void> {
    try {
      apiManager.sendSignupUrlByMail(this.email)
      alert(`メールを送信しました。`)
    } catch (e) {
      alert(`メール送信に失敗しました。`)
    }
  }
}
