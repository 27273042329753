

























import RouteInfos from '@/router/route_info'
import { Component, Emit, Prop, PropSync, Vue } from 'vue-property-decorator'

@Component
export default class AgeCertificateDialog extends Vue {
  @PropSync('isShowDialog', { type: Boolean, default: false })
  dialog!: boolean

  @Emit('close')
  close(): void {
    return
  }

  goToUserCertification() {
    this.$router.push(RouteInfos.UserCertification.path)
  }
}
