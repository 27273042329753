















































import Message from '@/net/sync/message'
import MessageType from '@/net/sync/types/message_type'
import User from '@/net/sync/user'
import firebase from 'firebase'
import { Component, Vue, Prop, Emit } from 'vue-property-decorator'

@Component
export default class ChatCard extends Vue {
  @Prop()
  isMe!: boolean

  @Prop()
  sendUser!: User

  @Prop()
  message!: Message

  @Prop()
  iconUrl!: string

  uploadedStorageFilePath = ''

  get userName(): string {
    return this.sendUser.name
  }

  get messageText(): string {
    return this.message.message
  }

  get isTextOnly(): boolean {
    return this.message.messageType === MessageType.ONLY_MESSAGE
  }

  get sendTimeString(): string {
    const sendDate = new Date(this.message.sendDate)
    return sendDate.toTimeString()
  }

  async mounted(): Promise<void> {
    if (this.message.uploadedStorageFilePath) {
      try {
        var spaceRef = firebase
          .storage()
          .ref()
          .child(this.message.uploadedStorageFilePath)
        this.uploadedStorageFilePath = await spaceRef.getDownloadURL()
      } catch (e) {
        // 画像の表示ができなかった場合には空の画像を表示する
        this.uploadedStorageFilePath = ''
      }
    }
  }

  @Emit('clickImage')
  clickImageAction(): void {
    return
  }
}
