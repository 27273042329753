import SexType from '@/net/sync/types/sex_type'
import firebase from 'firebase'

class AvatorImageUtil {
  DefaultManImage = require('@/assets/images/man_no_image.jpg')
  DefaultWomanImage = require('@/assets/images/woman_no_image.jpg')

  async getAvatorImageUrl(sexType: SexType, url?: string): Promise<string> {
    if (!url) {
      if (sexType == SexType.Man) {
        return this.DefaultManImage
      } else {
        return this.DefaultWomanImage
      }
    }

    try {
      const storageRef = firebase.storage().ref().child(url)
      const firebaseUrl = (await storageRef.getDownloadURL()) as string
      return firebaseUrl
    } catch (e) {
      if (sexType == SexType.Man) {
        return this.DefaultManImage
      } else {
        return this.DefaultWomanImage
      }
    }
  }
}

export default new AvatorImageUtil()
