export const setShowSearchDialog = 'setShowSearchDialog'
export const setSearchCondition = 'setSearchCondition'
export const clearSearchCondition = 'clearSearchCondition'
export const setHeaderUserInfo = 'setHeaderUserInfo'
export const showErrorDialog = 'showErrorDialog'
export const closeErrorDialog = 'closeErrorDialog'
export const showIndicator = 'showIndicator'
export const closeIndicator = 'closeIndicator'
export const showInformationDialog = 'showInformationDialog'
export const closeInformationDialog = 'closeInformationDialog'
