import { Entity } from 'typeorm'
import * as uuid from 'uuid'
import MessageType from './types/message_type'

/**
 * メッセージスレッド内のメッセージ
 */
@Entity('messages')
export default class Message {
  // @PrimaryColumn()
  id!: string

  // @Column({ name: 'message_thread_id' })
  messageThreadId!: string

  // @Column({ name: 'from_user_id' })
  fromUserId!: string

  // @Column({ name: 'is_read' })
  isRead!: boolean

  // @Column({ name: 'send_date' })
  sendDate!: Date

  // @Column({ name: 'message_type', type: 'int' })
  messageType!: MessageType

  // @Column({ name: 'message' })
  message!: string

  // @Column({ name: 'image_url' })
  uploadedStorageFilePath?: string

  // @CreateDateColumn({ name: 'created_at' })
  createdAt!: Date

  // @UpdateDateColumn({ name: 'updated_at' })
  updatedAt!: Date

  constructor(init?: Partial<Message>) {
    Object.assign(this, init)
    this.id = init?.id ?? uuid.v4()
    this.createdAt = init?.createdAt ?? new Date()
    this.updatedAt = init?.updatedAt ?? new Date()
  }
}
