








import { Component, Vue, Prop, PropSync } from 'vue-property-decorator'

@Component
export default class ImagePreviewDialog extends Vue {
  @PropSync('isShowDialog', { type: Boolean, default: false })
  showStatus!: boolean

  @Prop()
  previewImageUrl!: string
}
