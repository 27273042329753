


























































import apiManager from '@/net/api-manager'
import { Component, Vue } from 'vue-property-decorator'
import * as types from '@/store/mutation_types'
import store from '@/store/store'
import RouteInfos from '@/router/route_info'

@Component({
  components: {},
})
export default class ChangEMail extends Vue {
  validate = false
  email = ''
  confirmationCode = ''
  requestedToChangeEmail = false

  get validationForConfirm(): boolean {
    return this.confirmationCode != ''
  }

  async requestToChangeEmail(): Promise<void> {
    store.commit(types.showIndicator)

    if (this.validate) {
      await apiManager.requestChangeEmail(this.email)
    }
    this.requestedToChangeEmail = true
    store.commit(types.closeIndicator)
    store.commit(types.showInformationDialog, '確認コードを送信しました。')
  }

  async requestToConfirmEmail(): Promise<void> {
    store.commit(types.showIndicator)

    if (this.validationForConfirm) {
      await apiManager.confirmChangeEmail(this.confirmationCode)
    }
    store.commit(types.closeIndicator)
    store.commit(types.showInformationDialog, 'メールアドレスを変更しました。')
    this.$router.push(RouteInfos.MyPage.path)
  }
}
