

























import { Component, Vue } from 'vue-property-decorator'
import * as types from '@/store/mutation_types'
import store from '@/store/store'
import firebase from 'firebase'

@Component({
  components: {},
})
export default class ResetPassword extends Vue {
  valid = false
  email = ''

  async resetPassword(): Promise<void> {
    store.commit(types.showIndicator)
    try {
      await firebase.auth().sendPasswordResetEmail(this.email, null)
      store.commit(types.closeIndicator)
      store.commit(
        types.showInformationDialog,
        'パスワードリセット用のメールを送信しました。メールの案内に従って、パスワードをリセットしてください。'
      )
    } catch (e) {
      store.commit(types.closeIndicator)
      store.commit(
        types.showInformationDialog,
        'エラーが発生しました。再度実行してください。'
      )
    }
  }
}
