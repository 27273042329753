




































































import { Component, Vue } from 'vue-property-decorator'
import apiManager from '@/net/api-manager'
import RouteInfos from '@/router/route_info'
import * as types from '@/store/mutation_types'
import store from '@/store/store'

@Component({
  components: {},
})
export default class UserCertification extends Vue {
  isAgeCertified = false
  uploadImageDataUrl = ''
  selectFileName = ''

  get refs(): any {
    return this.$refs
  }

  async mounted(): Promise<void> {
    store.commit(types.showIndicator)
    const me = await apiManager.getMe()
    this.isAgeCertified = me.isAgeCertified
    store.commit(types.closeIndicator)
  }

  onClickSelectImage(): void {
    this.refs.inputImage.click()
  }

  async selectedFile(): Promise<void> {
    const file = this.refs.inputImage.files[0] as File
    if (!file) {
      return
    }
    const fr = new FileReader()
    fr.readAsDataURL(file)
    fr.addEventListener('load', () => {
      this.uploadImageDataUrl = fr.result as string
      this.selectFileName = file.name
    })
  }

  async registerImage(): Promise<void> {
    if (this.uploadImageDataUrl === '') {
      return
    }
    store.commit(types.showIndicator)
    const imageBlob = await fetch(this.uploadImageDataUrl).then((file) =>
      file.blob()
    )
    try {
      const response = await apiManager.postUserCertificationImage(
        imageBlob as File
      )
      this.$router.push(RouteInfos.MyPage.path)
    } catch (e) {
      // FIXME: アラート表示は暫定対応（ハンドリング場所も）
      switch (e.code) {
        case '413':
          store.commit(
            types.showErrorDialog,
            'アップロードする画像のサイズが大きすぎます'
          )
          break
        default:
          store.commit(types.showErrorDialog, '予期せぬエラーが発生しました')
          break
      }
    }
    store.commit(types.closeIndicator)
  }
}
