






















































































import apiManager from '@/net/api-manager'
import SexType from '@/net/sync/types/sex_type'
import User from '@/net/sync/user'
import { Component, Vue } from 'vue-property-decorator'
import firebase from 'firebase'
import ImageCropperDialog from '@/components/organisms/ImageCropperDialog.vue'
import store from '@/store/store'
import * as types from '../store/mutation_types'
import DownloadImageUtil from '../util/download_image_util'

interface ImageInfo {
  userProfileImageId: string
  url: string
}

@Component({
  components: {
    ImageCropperDialog,
  },
})
export default class EditMyProfileImagePage extends Vue {
  initialized = false
  user: User | undefined = undefined

  defaultManImage = require('@/assets/images/man_no_image.jpg')
  defaultWomanImage = require('@/assets/images/woman_no_image.jpg')
  addingImage = require('@/assets/images/adding_image.svg')

  mainImage: ImageInfo | undefined = undefined
  subImages: ImageInfo[] = []

  // メイン画像の追加の場合はtrue
  isMainImageAdding = false
  uploadImageDataUrl = ''
  isShowCropperDialog = false

  get defaultImage(): string {
    return this.user?.sexType === SexType.Man
      ? this.defaultManImage
      : this.defaultWomanImage
  }

  get refs(): any {
    return this.$refs
  }

  async created(): Promise<void> {
    store.commit(types.showIndicator)
    await this.init()
    this.initialized = true
    store.commit(types.closeIndicator)
  }

  async init(): Promise<void> {
    this.user = await apiManager.getMe()
    this.mainImage = undefined
    this.subImages = []
    if (this.user.userProfileImages) {
      const mainImage = this.user.userProfileImages.find((i) => i.isMain)
      if (mainImage?.imageUrl) {
        const url = await DownloadImageUtil.getImageUrl(mainImage.imageUrl)
        console.log(url)
        this.mainImage = { userProfileImageId: mainImage.id, url: url }
      }
      const subImages = this.user.userProfileImages
        .filter((i) => !i.isMain)
        .sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1)) // updatedAtで降順
      for await (const i of subImages) {
        const url = await DownloadImageUtil.getImageUrl(i.imageUrl)
        this.subImages.push({ userProfileImageId: i.id, url: url })
      }
    }
    this.$forceUpdate()
  }

  onClickSelectImage(isMain: boolean): void {
    this.isMainImageAdding = isMain
    this.refs.inputImage.click()
  }

  async selectedFile(): Promise<void> {
    const file = this.refs.inputImage.files[0] as File
    if (!file) {
      return
    }
    const fr = new FileReader()
    fr.readAsDataURL(file)
    fr.addEventListener('load', () => {
      this.uploadImageDataUrl = fr.result as string
      this.isShowCropperDialog = true
    })
  }

  async onCroppedImage(image: string): Promise<void> {
    store.commit(types.showIndicator)
    const croppedImage = await this.blurImage(image)
    try {
      const imageBlob = await fetch(croppedImage).then((file) => file.blob())
      if (this.isMainImageAdding) {
        await apiManager.postUserMainImage(imageBlob as File)
      } else {
        await apiManager.postUserSubImage(imageBlob as File)
      }
      this.uploadImageDataUrl = ''
      await this.init()
      store.commit(types.closeIndicator)
    } catch (e) {
      // FIXME: アラート表示は暫定対応（ハンドリング場所も）
      switch (e.code) {
        case '413':
          alert('アップロードする画像のサイズが大きすぎます')
          break
        default:
          alert('予期せぬエラーが発生しました')
          break
      }
    }
  }

  async blurImage(image: string): Promise<string> {
    const imageBlob = await fetch(image).then((file) => file.blob())
    const a = await apiManager.postTransformBlur(imageBlob as File)
    const blob = new Blob([a])
    return window.URL.createObjectURL(blob)
  }

  async onClickDelete(userProfileImageId: string): Promise<void> {
    store.commit(types.showIndicator)
    await apiManager.deleteUserSubImage(userProfileImageId)
    await this.init()
    store.commit(types.closeIndicator)
  }
}
