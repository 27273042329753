export {}

declare global {
  interface Date {
    getUnixTime(): number
    toTimeString(): string
  }
}

Date.prototype.getUnixTime = function () {
  return Math.floor(this.getTime() / 1000)
}

Date.prototype.toTimeString = function () {
  return `${this.getHours().toString().padStart(2, '0')}:${this.getMinutes()
    .toString()
    .padStart(2, '0')}`
}
