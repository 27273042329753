import * as type from './mutation_types'
import { ErrorContext, HeaderUser, State } from './state'
import RequestUserSearchCondition from '@/net/sync/request/request_user_search_condition'

export default {
  [type.setShowSearchDialog](state: State, showSearchDialog: boolean): void {
    state.showSearchDialog = showSearchDialog
  },
  [type.setSearchCondition](
    state: State,
    searchCondition: RequestUserSearchCondition
  ): void {
    state.searchCondition = searchCondition
  },
  [type.clearSearchCondition](state: State): void {
    state.searchCondition = {
      areaType: undefined,
      ageFrom: undefined,
      ageTo: undefined,
      heightFrom: undefined,
      heightTo: undefined,
      styleType: undefined,
      drinkFrequencyType: undefined,
      smokingFrequencyType: undefined,
      page: 0,
    }
  },
  [type.setHeaderUserInfo](state: State, user: HeaderUser): void {
    state.headerUserInfo = user
  },
  [type.showErrorDialog](state: State, message: string): void {
    const errorContext = {
      title: 'エラーが発生しました',
      message: message,
    }

    state.errorContext = errorContext
    state.isShowIndicator = false
    state.isShowErrorDialog = true
  },
  [type.closeErrorDialog](state: State): void {
    state.isShowErrorDialog = false
    state.isShowIndicator = false
    state.errorContext = undefined
  },
  [type.showIndicator](state: State): void {
    state.isShowIndicator = true
  },
  [type.closeIndicator](state: State): void {
    state.isShowIndicator = false
  },
  [type.showInformationDialog](state: State, message: string): void {
    state.isShowInformationDialog = true
    state.informationMessage = message
  },
  [type.closeInformationDialog](state: State): void {
    state.isShowInformationDialog = false
    state.informationMessage = ''
  },
}
