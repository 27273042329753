













import apiManager from '@/net/api-manager'
import { Component, Vue } from 'vue-property-decorator'
import AccountRegisterForm from '../components/organisms/AccountRegisterForm.vue'
import RequestSignUpInfo from '../net/sync/request/request_signup_info'
import firebase from 'firebase'
import * as types from '@/store/mutation_types'
import store from '@/store/store'

@Component({
  components: { AccountRegisterForm },
})
export default class AccountRegister extends Vue {
  async register(form: RequestSignUpInfo): Promise<void> {
    if (!form) {
      return
    }
    try {
      store.commit(types.showIndicator)
      const email = await apiManager.singup(form)

      // サーバーに登録できたらLoginも行う
      // TODO: フロントで無理やりログインしててちょっと気持ち悪い。サーバーでログインしちゃっていいかも
      await firebase.auth().signInWithEmailAndPassword(email, form.password)
      this.$router.push(
        `/account-register/${this.$route.params.user_invitation_id}/image-upload`
      )
      store.commit(types.closeIndicator)
    } catch {
      store.commit(types.showErrorDialog, 'ユーザー登録に失敗しました。')
    }
  }
}
