enum SMOKING_FREQUENCY_TYPE {
  NONE = 'NONE',
  吸う = '吸う',
  吸わない = '吸わない',
  電子タバコなら吸う = '電子タバコなら吸う',
  非喫煙者の前では吸わない = '非喫煙者の前では吸わない',
  相手が嫌なら吸わない = '相手が嫌なら吸わない',
}

export default SMOKING_FREQUENCY_TYPE
