







































import { Component, Vue } from 'vue-property-decorator'
import firebase from 'firebase'
import { Mutation } from 'vuex-class'
import * as mutation_types from '@/store/mutation_types'
import { ErrorContext } from '@/store/state'
import RouteInfos from '@/router/route_info'
import store from '@/store/store'
import * as types from '@/store/mutation_types'

@Component({
  components: {},
})
export default class Login extends Vue {
  mailAddress = ''
  password = ''
  @Mutation(mutation_types.showErrorDialog) showShowErrorDialog!: (
    context: ErrorContext
  ) => void
  @Mutation(mutation_types.showIndicator)
  showIndicator!: () => void
  @Mutation(mutation_types.closeIndicator)
  closeIndicator!: () => void

  isDebugging = process.env.NODE_ENV != 'production'

  async login(): Promise<void> {
    store.commit(types.showIndicator)
    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword(this.mailAddress, this.password)
      store.commit(types.closeIndicator)
      this.$router.push(RouteInfos.SearchPage.path)
    } catch (e) {
      store.commit(types.showErrorDialog, 'ログインに失敗しました。')
    }
  }
}
