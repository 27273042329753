













import { Component, Vue } from 'vue-property-decorator'
import { Mutation, State } from 'vuex-class'
import { ErrorContext } from '@/store/state'
import * as mutationTypes from '@/store/mutation_types'

@Component
export default class ErrorDialog extends Vue {
  @State('isShowErrorDialog') private readonly isShowErrorDialog!: boolean
  @State('errorContext') private readonly errorContext?: ErrorContext
  @Mutation(mutationTypes.closeErrorDialog) closeErrorDialog!: () => void

  get errorTitle(): string {
    return this.errorContext?.title ?? ''
  }
  get errorMessage(): string {
    return this.errorContext?.message ?? ''
  }
}
