











































































import { Component, Vue } from 'vue-property-decorator'
import RouteInfos from '../router/route_info'
import apiManager from '@/net/api-manager'
import User from '@/net/sync/user'
import SexType from '@/net/sync/types/sex_type'
// eslint-disable-next-line
import VoidFunction from 'typescript'
import firebase from 'firebase'
import * as types from '@/store/mutation_types'
import store from '@/store/store'
import PaymentUser from '@/net/sync/payment_users'

interface MyPageFeature {
  title: string
  action: VoidFunction
}

@Component({
  components: {},
})
export default class MyPage extends Vue {
  imgMaxHeight = '25px'
  contentHeight = '85px'
  contentWidth = '95px'

  myUser?: User
  paymentUser?: PaymentUser
  name = ''
  imageUrl = ''
  isAgeCertified = false
  isFree = false
  isPaid = false

  defaultManImage = require('@/assets/images/man_no_image.jpg')
  defaultWomanImage = require('@/assets/images/woman_no_image.jpg')

  myPageBaseFeatures: MyPageFeature[] = [
    {
      title: '通知設定',
      action: (): void => {
        this.$router.push('/notification-setting')
      },
    },
    {
      title: 'メールアドレス変更',
      action: (): void => {
        this.$router.push('/change-email')
      },
    },
    {
      title: 'パスワード変更',
      action: (): void => {
        this.resetPassword()
      },
    },
    {
      title: 'ログアウト',
      action: (): void => {
        firebase.auth().signOut()
        this.$router.push(RouteInfos.Login.path)
      },
    },
  ]

  termItems: MyPageFeature[] = [
    {
      title: '会社概要',
      action: (): void => {
        this.openExternalLink('https://mrmrs.jp/company.html')
      },
    },
    {
      title: '会員規約',
      action: (): void => {
        this.openExternalLink('https://mrmrs.jp/terms.html')
      },
    },
    {
      title: '特商法',
      action: (): void => {
        this.openExternalLink('https://mrmrs.jp/specific_trade_law.html')
      },
    },
    {
      title: 'プライバシーポリシー',
      action: (): void => {
        this.openExternalLink('https://mrmrs.jp/privacy.html')
      },
    },
  ]

  get myPageFeatures(): MyPageFeature[] {
    return !this.isFree
      ? [
          {
            title: '料金プラン',
            action: (): void => {
              this.$router.push('/payment')
            },
          },
          ...this.myPageBaseFeatures,
        ]
      : this.myPageBaseFeatures
  }

  get isShowAgeConfirmBuotton(): boolean {
    return !this.isAgeCertified
  }

  get isShowPaymentButton(): boolean {
    return !this.isFree && !this.isPaid
  }

  colsNum(index: number): number {
    const num = ((index + 1) * 4) % 12
    num === 0 ? 12 : num

    console.log(num === 0 ? 12 : num)
    return num === 0 ? 12 : num
  }

  async mounted(): Promise<void> {
    store.commit(types.showIndicator)
    this.myUser = await apiManager.getMe()
    this.paymentUser = await apiManager.getPaymentUser()
    this.isFree = this.paymentUser?.isFree ?? false
    this.isPaid = this.paymentUser?.isPaid ?? false
    this.isAgeCertified = this.myUser?.isAgeCertified ?? false
    this.name = this.myUser.name
    await this.setImageUrl()
    store.commit(types.closeIndicator)
  }

  async setImageUrl(): Promise<void> {
    if (this.myUser?.avatarImageUrl) {
      const storageRef = firebase
        .storage()
        .ref()
        .child(this.myUser.avatarImageUrl)
      const url = (await storageRef.getDownloadURL()) as string
      this.imageUrl = url
      return
    }

    if (this.myUser?.sexType == SexType.Man) {
      this.imageUrl = this.defaultManImage
    } else {
      this.imageUrl = this.defaultWomanImage
    }
  }

  goToByRouterInfoPath(name: string) {
    const path = RouteInfos[name].path
    this.$router.push(path)
  }

  goToUserCertificationPage() {
    this.$router.push(RouteInfos.UserCertification.path)
  }

  goToPaymentPage() {
    this.$router.push(RouteInfos.Payment.path)
  }

  goToEditProfilePage() {
    this.$router.push(RouteInfos.EditProfile.path)
  }

  openExternalLink(url: string) {
    window.open(url, '_blank')
  }

  async resetPassword(): Promise<void> {
    store.commit(types.showIndicator)
    const me = await apiManager.getMe()
    await firebase.auth().sendPasswordResetEmail(me.email, null)
    store.commit(types.closeIndicator)
    store.commit(
      types.showInformationDialog,
      'パスワードリセット用のメールを送信しました。メールの案内に従って、パスワードをリセットしてください。'
    )
  }
}
