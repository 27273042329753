








import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

@Component
export default class Indicator extends Vue {
  @State('isShowIndicator')
  private readonly isShowIndicator!: boolean
}
