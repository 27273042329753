





























import apiManager from '@/net/api-manager'
import SexType from '@/net/sync/types/sex_type'
import User from '@/net/sync/user'
import RouteInfos from '@/router/route_info'
import store from '@/store/store'
import { Component, Vue } from 'vue-property-decorator'
import AccountUpdateFrom from '../components/organisms/AccountUpdateForm.vue'
import * as types from '../store/mutation_types'
import DownloadImageUtil from '../util/download_image_util'

@Component({
  components: { AccountUpdateFrom },
})
export default class EditProfile extends Vue {
  initialized = false
  user: User | undefined = undefined
  defaultManImage = require('@/assets/images/man_no_image.jpg')
  defaultWomanImage = require('@/assets/images/woman_no_image.jpg')
  userImages: string[] = []

  async mounted(): Promise<void> {
    store.commit(types.showIndicator)
    this.user = await apiManager.getMe()
    this.initialized = true
    store.commit(types.closeIndicator)

    if (
      !this.user.userProfileImages ||
      this.user.userProfileImages.length === 0
    ) {
      const defaultImage = this.getDefaultImage(this.user.sexType)
      this.userImages = [defaultImage]
    } else {
      this.userImages = await Promise.all(
        // TODO: 要ソート
        this.user.userProfileImages.map(async (image) => {
          const url = await DownloadImageUtil.getImageUrl(image.imageUrl)
          return url
        })
      )
    }
  }
  async update(): Promise<void> {
    store.commit(types.showInformationDialog, '更新しました')
  }

  getRouterInfoPath(name: string): string {
    return RouteInfos[name].path
  }

  goEditMyProfileImagePage() {
    this.$router.push(this.getRouterInfoPath('EditMyProfileImagePage'))
  }

  getDefaultImage(sexType: SexType): string {
    return sexType === SexType.Man
      ? this.defaultManImage
      : this.defaultWomanImage
  }
}
