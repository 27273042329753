import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Login from '../pages/LoginPage.vue'
import MessageThreadListPage from '../pages/MessageThreadListPage.vue'
import MessageThreadPage from '../pages/MessageThreadPage.vue'
import SearchPage from '../pages/SearchPage.vue'
import OtherProfilePage from '../pages/OtherProfilePage.vue'
import FavoriteReceipt from '../pages/FavoriteReceipt.vue'
import MyPage from '../pages/MyPage.vue'
import VisitHistory from '../pages/VisitHistoryPage.vue'
import AccountRegister from '../pages/AccountRegisterPage.vue'
import AccountRegisterImageUploadPage from '../pages/AccountRegisterImageUploadPage.vue'
import RouteInfos from './route_info'
import Payment from '../pages/PaymentPage.vue'
import UserCertification from '../pages/UserCertificationPage.vue'
import EditProfile from '../pages/EditProfilePage.vue'
import LandingPage from '../pages/LandingPage.vue'
import EditMyProfileImagePage from '../pages/EditMyProfileImagePage.vue'
import ChangeEmail from '../pages/ChangeEmailPage.vue'
import ResetPassword from '../pages/ResetPasswordPage.vue'
import NotificationSetting from '../pages/NotificationSettingPage.vue'
import store from '../store/store'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: RouteInfos.SearchPage.name,
    component: SearchPage,
    meta: {
      info: RouteInfos.SearchPage,
    },
  },
  // TODO: LPできたら消す
  {
    path: RouteInfos.LandingPage.path,
    name: RouteInfos.LandingPage.name,
    component: LandingPage,
    meta: {
      info: RouteInfos.LandingPage,
      isPublic: true,
    },
  },
  {
    path: RouteInfos.Login.path,
    name: RouteInfos.Login.name,
    component: Login,
    meta: {
      info: RouteInfos.Login,
      isPublic: true,
    },
  },
  {
    path: RouteInfos.AccountRegister.path,
    name: RouteInfos.AccountRegister.name,
    component: AccountRegister,
    meta: {
      info: RouteInfos.AccountRegister,
      isPublic: true,
    },
  },
  {
    path: RouteInfos.AccountRegisterImageUploadPage.path,
    name: RouteInfos.AccountRegisterImageUploadPage.name,
    component: AccountRegisterImageUploadPage,
    meta: {
      info: RouteInfos.AccountRegisterImageUploadPage,
      isPublic: true,
    },
  },
  {
    path: RouteInfos.MessageThreadList.path,
    name: RouteInfos.MessageThreadList.name,
    component: MessageThreadListPage,
    meta: {
      info: RouteInfos.MessageThreadList,
    },
  },
  {
    path: RouteInfos.MessageThread.path,
    name: RouteInfos.MessageThread.name,
    component: MessageThreadPage,
    props: (route) => ({ message_thread_id: route.params.message_thread_id }),
    meta: {
      info: RouteInfos.MessageThread,
    },
  },
  {
    path: RouteInfos.SearchPage.path,
    name: RouteInfos.SearchPage.name,
    component: SearchPage,
    meta: {
      info: RouteInfos.SearchPage,
    },
  },
  {
    path: RouteInfos.OtherProfilePage.path,
    name: RouteInfos.OtherProfilePage.name,
    component: OtherProfilePage,
    meta: {
      info: RouteInfos.OtherProfilePage,
    },
  },
  {
    path: RouteInfos.FavoriteReceipt.path,
    name: RouteInfos.FavoriteReceipt.name,
    component: FavoriteReceipt,
    meta: {
      info: RouteInfos.FavoriteReceipt,
    },
  },
  {
    path: RouteInfos.MyPage.path,
    name: RouteInfos.MyPage.name,
    component: MyPage,
    meta: {
      info: RouteInfos.MyPage,
    },
  },
  {
    path: RouteInfos.VisitHistory.path,
    name: RouteInfos.VisitHistory.name,
    component: VisitHistory,
    meta: {
      info: RouteInfos.VisitHistory,
    },
  },
  {
    path: RouteInfos.Payment.path,
    name: RouteInfos.Payment.name,
    component: Payment,
    meta: {
      info: RouteInfos.Payment,
    },
  },
  {
    path: RouteInfos.UserCertification.path,
    name: RouteInfos.UserCertification.name,
    component: UserCertification,
    meta: {
      info: RouteInfos.UserCertification,
    },
  },
  {
    path: RouteInfos.EditProfile.path,
    name: RouteInfos.EditProfile.name,
    component: EditProfile,
    meta: {
      info: RouteInfos.EditProfile,
    },
  },
  {
    path: RouteInfos.EditMyProfileImagePage.path,
    name: RouteInfos.EditMyProfileImagePage.name,
    component: EditMyProfileImagePage,
    meta: {
      info: RouteInfos.EditMyProfileImagePage,
    },
  },
  {
    path: RouteInfos.ChangEmail.path,
    name: RouteInfos.ChangEmail.name,
    component: ChangeEmail,
    meta: {
      info: RouteInfos.ChangEmail,
    },
  },
  {
    path: RouteInfos.ResetPassword.path,
    name: RouteInfos.ResetPassword.name,
    component: ResetPassword,
    meta: {
      info: RouteInfos.ResetPassword,
      isPublic: true,
    },
  },
  {
    path: RouteInfos.NotificationSetting.path,
    name: RouteInfos.NotificationSetting.name,
    component: NotificationSetting,
    meta: {
      info: RouteInfos.NotificationSetting,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.isPublic)) {
    next()
    return
  }
  if (!store.getters.isSignin()) {
    next('/login')
    return
  }
  next()
  return
})

export default router
