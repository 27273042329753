
































































import apiManager from '@/net/api-manager'
import User from '@/net/sync/user'
import { Component, Vue } from 'vue-property-decorator'
import { Mutation } from 'vuex-class'
import * as types from '../store/mutation_types'
import SexType from '@/net/sync/types/sex_type'
import { HeaderUser } from '@/store/state'
import STYLE_TYPE from '@/net/sync/types/style_type'
import DRINK_FREQUENCY_TYPE from '@/net/sync/types/drink_frequency_type'
import SMOKING_FREQUENCY_TYPE from '@/net/sync/types/smoking_frequency_type'
import AvatorImageUtil from '../util/avator_image_util'
import store from '@/store/store'
import DownloadImageUtil from '../util/download_image_util'

@Component
export default class OtherProfilePage extends Vue {
  @Mutation(types.setHeaderUserInfo) setHeaderUserInfo!: (
    user: HeaderUser
  ) => void

  defaultManImage = require('@/assets/images/man_no_image.jpg')
  defaultWomanImage = require('@/assets/images/woman_no_image.jpg')
  user?: User = undefined
  userImages: string[] = []
  initialized = false // TODO: こんなフラグ用意しないでもいけるはず・・・

  get userStyleType(): string {
    const styleType = this.user?.userProfile?.styleType
    if (!styleType || styleType === STYLE_TYPE.NONE) {
      return '--'
    }
    return styleType
  }

  get userDrinkFrequencyType(): string {
    const drinkFrequencyType = this.user?.userProfile?.drinkFrequencyType
    if (
      !drinkFrequencyType ||
      drinkFrequencyType === DRINK_FREQUENCY_TYPE.NONE
    ) {
      return '--'
    }
    return drinkFrequencyType
  }

  get userSmokingFrequencyType(): string {
    const smokingFrequencyType = this.user?.userProfile?.smokingFrequencyType
    if (
      !smokingFrequencyType ||
      smokingFrequencyType === SMOKING_FREQUENCY_TYPE.NONE
    ) {
      return '--'
    }
    return smokingFrequencyType
  }

  async mounted(): Promise<void> {
    store.commit(types.showIndicator)
    this.user = await apiManager.getUser(this.$route.params.user_id)
    await this.setSortedImages()
    const url = await AvatorImageUtil.getAvatorImageUrl(
      this.user.sexType,
      this.user.avatarImageUrl
    )
    this.setHeaderUserInfo({
      name: this.user.name,
      sexType: this.user.sexType,
      avatarImageUrl: url,
    })
    this.initialized = true
    store.commit(types.closeIndicator)
  }

  async favorite(): Promise<void> {
    if (!this.user) return
    store.commit(types.showIndicator)
    await apiManager.postRegisterFavorite(this.user.id)
    store.commit(types.closeIndicator)
    store.commit(types.showInformationDialog, 'いいねを送りました')
  }

  async setSortedImages() {
    store.commit(types.showIndicator)
    if (!this.user) {
      this.userImages = []
    } else if (
      !this.user.userProfileImages ||
      this.user.userProfileImages.length === 0
    ) {
      this.userImages =
        this.user.sexType === SexType.Man
          ? [this.defaultManImage]
          : [this.defaultWomanImage]
    } else {
      this.userImages = await Promise.all(
        // TODO: 要ソート
        this.user.userProfileImages.map(async (image) => {
          const url = await DownloadImageUtil.getImageUrl(image.imageUrl)
          return url
        })
      )
    }
    store.commit(types.closeIndicator)
  }

  profiles = [
    {
      avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
      key: '年齢',
      value: '37歳',
    },
    { divider: true, inset: false },
    {
      avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
      key: '年齢',
      value: '37歳',
    },
    { divider: true, inset: false },
    {
      avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
      key: '年齢',
      value: '37歳',
    },
  ]
}
