import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store/store'
import firebase from 'firebase'
import { firebaseConfigStg } from './conf/firebase-config-stg'
import { firebaseConfigProd } from './conf/firebase-config-prod'
import InfiniteLoading from 'vue-infinite-loading'
import apiManager from './net/api-manager'
import VueGtag from 'vue-gtag'

const main = async () => {
  Vue.config.productionTip = false
  if (process.env.VUE_APP_MANDM_ENV === 'production') {
    firebase.initializeApp(firebaseConfigProd)
  } else {
    firebase.initializeApp(firebaseConfigStg)
  }
  firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
  await apiManager.init()
  await currentUser()

  Vue.use(VueGtag, {
    config: { id: process.env.VUE_APP_GOOGLE_TAG },
  })

  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount('#app')
  // Vue.use(InfiniteLoading, {
  //   /* options */
  // })
}

// firebase.auth().currentUserではuserを取得できないことがある。
// 参考：https://firebase.google.com/docs/auth/web/manage-users?hl=ja
const currentUser = () => {
  return new Promise<firebase.User | null>((resoleve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      resoleve(user)
      unsubscribe()
    }, reject)
  })
}

main()
