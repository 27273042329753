






































import RouteInfos, { RouteInfo } from '@/router/route_info'
import store from '@/store/store'
import { Component, Vue } from 'vue-property-decorator'
import { Mutation, State } from 'vuex-class'
import RequestUserSearchCondition from '@/net/sync/request/request_user_search_condition'
import AgeSelectType from '@/model/types/age-select-type'
import HeightSelectType from '@/model/types/height-select-type'
import SexType from '@/net/sync/types/sex_type'
import { HeaderUser } from '@/store/state'

@Component
export default class Header extends Vue {
  @Mutation('setShowSearchDialog') setShowSearchDialog!: (
    showSearchDialog: boolean
  ) => void

  @State('searchCondition')
  private readonly searchCondition!: RequestUserSearchCondition

  @State('headerUserInfo')
  private readonly headerUserInfo!: HeaderUser

  get searchConditionStr(): string {
    let str = ''
    let isFirst = true
    if (this.searchCondition.areaType) {
      str = str.concat(
        `${this.prefix(isFirst)}${this.searchCondition.areaType}`
      )
      isFirst = false
    }
    if (this.searchCondition.ageTo || this.searchCondition.ageFrom) {
      const ageTo = this.searchCondition.ageTo
      const ageFrom = this.searchCondition.ageFrom
      const name = Object.values(AgeSelectType).find(
        (a) => a.ageTo === ageTo && a.ageFrom === ageFrom
      )?.name
      if (name) {
        str = str.concat(`${this.prefix(isFirst)}${name}`)
        isFirst = false
      }
    }
    if (this.searchCondition.heightTo || this.searchCondition.heightFrom) {
      const heightTo = this.searchCondition.heightTo
      const heightFrom = this.searchCondition.heightFrom
      const name = Object.values(HeightSelectType).find(
        (h) => h.heightTo === heightTo && h.heightFrom === heightFrom
      )?.name
      if (name) {
        str = str.concat(`${this.prefix(isFirst)}${name}`)
        isFirst = false
      }
    }
    if (this.searchCondition.styleType) {
      str = str.concat(
        `${this.prefix(isFirst)}${this.searchCondition.styleType}`
      )
      isFirst = false
    }
    if (this.searchCondition.drinkFrequencyType) {
      str = str.concat(
        `${this.prefix(isFirst)}${this.searchCondition.drinkFrequencyType}`
      )
      isFirst = false
    }
    if (this.searchCondition.smokingFrequencyType) {
      str = str.concat(
        `${this.prefix(isFirst)}${this.searchCondition.smokingFrequencyType}`
      )
      isFirst = false
    }
    return str
  }

  private prefix(isFirst: boolean): string {
    return isFirst ? '' : ','
  }

  get routeName(): string {
    return this.$route.name ?? ''
  }

  get routeInfo(): RouteInfo {
    return RouteInfos[this.routeName]
  }

  get isSearchPage(): boolean {
    return this.routeName === RouteInfos['SearchPage'].name
  }

  get headerTitle(): string {
    return this.headerUserInfo?.name ?? ''
  }

  get headerIconUrl(): string {
    if (!this.headerUserInfo) return ''
    if (!this.headerUserInfo.avatarImageUrl) {
      if (this.headerUserInfo.sexType == SexType.Man) {
        return require('@/assets/images/man_no_image.jpg')
      } else {
        return require('@/assets/images/woman_no_image.jpg')
      }
    }
    return this.headerUserInfo.avatarImageUrl
  }
}
