// 頑張って計算で作ろうとすると逆にめんどかったから定義
const HeightSelectType = {
  Under140: { name: '140cm未満', heightFrom: undefined, heightTo: 140 },
  Between140And144: { name: '140 - 144cm', heightFrom: 140, heightTo: 144 },
  Between145And149: { name: '145 - 149cm', heightFrom: 145, heightTo: 149 },
  Between150And154: { name: '150 - 154cm', heightFrom: 150, heightTo: 154 },
  Between155And159: { name: '155 - 159cm', heightFrom: 155, heightTo: 159 },
  Between160And164: { name: '160 - 164cm', heightFrom: 160, heightTo: 164 },
  Between165And169: { name: '165 - 169cm', heightFrom: 165, heightTo: 169 },
  Between170And174: { name: '170 - 174cm', heightFrom: 170, heightTo: 174 },
  Between175And179: { name: '175 - 179cm', heightFrom: 175, heightTo: 179 },
  Between180And184: { name: '180 - 184cm', heightFrom: 180, heightTo: 184 },
  Between185And189: { name: '185 - 189cm', heightFrom: 185, heightTo: 189 },
  Over190: { name: '190cm以上', heightFrom: 190, heightTo: undefined },
}

export default HeightSelectType
