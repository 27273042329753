

























import {
  Component,
  Vue,
  PropSync,
  Prop,
  Emit,
  Watch,
} from 'vue-property-decorator'
import 'cropperjs/dist/cropper.css'
import VueCropper from 'vue-cropperjs'

@Component({
  components: { VueCropper },
})
export default class ImageCropperDialog extends Vue {
  @PropSync('isShowDialog', { type: Boolean, default: false })
  isShow!: boolean
  @Prop({ type: String, default: '' })
  selectedImage = ''
  private cropImage = ''

  get refs(): any {
    return this.$refs
  }

  @Watch('selectedImage')
  onChangeSelectedImage() {
    if (this.selectedImage && this.refs.cropper) {
      this.refs.cropper.replace(this.selectedImage, false)
    }
  }

  @Emit('onCropImage')
  onCropImage(): string {
    this.cropImage = this.refs.cropper
      .getCroppedCanvas({ width: 500, height: 500 })
      .toDataURL()
    this.isShow = false
    return this.cropImage
  }
}
