






























































import apiManager from '@/net/api-manager'
import AREA_TYPE from '@/net/sync/types/area_type'
import DRINK_FREQUENCY_TYPE from '@/net/sync/types/drink_frequency_type'
import SMOKING_FREQUENCY_TYPE from '@/net/sync/types/smoking_frequency_type'
import STYLE_TYPE from '@/net/sync/types/style_type'
import RequestEditProfile from '@/net/sync/request/request_edit_profile'
import { Component, Emit, Vue } from 'vue-property-decorator'
import UserProfile from '@/net/sync/user_profile'

@Component({
  components: {},
})
export default class AccountUpdateFrom extends Vue {
  name = ''
  modelProfile: UserProfile = {
    id: '',
    userId: '',
    age: 0,
    areaType: AREA_TYPE.NONE,
    introduction: '',
    height: 0,
    styleType: STYLE_TYPE.NONE,
    drinkFrequencyType: DRINK_FREQUENCY_TYPE.NONE,
    smokingFrequencyType: SMOKING_FREQUENCY_TYPE.NONE,
    createdAt: new Date(),
    updatedAt: new Date(),
    user: undefined,
  }

  ageList = Array.from(new Array(70)).map((_v, i) => i + 20)
  heightList = Array.from(new Array(90)).map((_v, i) => i + 130)

  async mounted(): Promise<void> {
    const me = await apiManager.getMe()
    this.name = me.name
    this.modelProfile = me.userProfile!
  }

  get areaTypes(): Array<string> {
    return Object.keys(AREA_TYPE).filter((s) => s !== AREA_TYPE.NONE)
  }

  get styleTypes(): Array<string> {
    return Object.keys(STYLE_TYPE).filter((s) => s !== STYLE_TYPE.NONE)
  }

  get drinkFrequencyTypes(): Array<string> {
    return Object.keys(DRINK_FREQUENCY_TYPE).filter(
      (d) => d !== DRINK_FREQUENCY_TYPE.NONE
    )
  }

  get smokingFrequencyTypes(): Array<string> {
    return Object.keys(SMOKING_FREQUENCY_TYPE).filter(
      (s) => s !== SMOKING_FREQUENCY_TYPE.NONE
    )
  }

  @Emit()
  async submit() {
    const req: RequestEditProfile = {
      name: this.name,
      userProfile: this.modelProfile,
    }
    await apiManager.postMyProfile(req)
  }

  todofuken = [
    '北海道',
    '青森県',
    '岩手県',
    '宮城県',
    '秋田県',
    '山形県',
    '福島県',
    '茨城県',
    '栃木県',
    '群馬県',
    '埼玉県',
    '千葉県',
    '東京都',
    '神奈川県',
    '新潟県',
    '富山県',
    '石川県',
    '福井県',
    '山梨県',
    '長野県',
    '岐阜県',
    '静岡県',
    '愛知県',
    '三重県',
    '滋賀県',
    '京都府',
    '大阪府',
    '兵庫県',
    '奈良県',
    '和歌山県',
    '鳥取県',
    '島根県',
    '岡山県',
    '広島県',
    '山口県',
    '徳島県',
    '香川県',
    '愛媛県',
    '高知県',
    '福岡県',
    '佐賀県',
    '長崎県',
    '熊本県',
    '大分県',
    '宮崎県',
    '鹿児島県',
    '沖縄県',
  ]
}
