// 頑張って計算で作ろうとすると逆にめんどかったから定義
const AgeSelectType = {
  Under20: { name: '20歳未満', ageFrom: undefined, ageTo: 19 },
  Between20And24: { name: '20 - 24歳', ageFrom: 20, ageTo: 24 },
  Between25And29: { name: '25 - 29歳', ageFrom: 25, ageTo: 29 },
  Between30And34: { name: '30 - 34歳', ageFrom: 30, ageTo: 34 },
  Between35And39: { name: '35 - 39歳', ageFrom: 35, ageTo: 39 },
  Between40And44: { name: '40 - 44歳', ageFrom: 40, ageTo: 44 },
  Between45And49: { name: '45 - 49歳', ageFrom: 45, ageTo: 49 },
  Between50And54: { name: '50 - 54歳', ageFrom: 50, ageTo: 54 },
  Between55And59: { name: '55 - 49歳', ageFrom: 55, ageTo: 59 },
  Between60And64: { name: '60 - 64歳', ageFrom: 60, ageTo: 64 },
  Over65: { name: '65歳以上', ageFrom: 65, ageTo: undefined },
}
export default AgeSelectType
