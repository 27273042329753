import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios'
import firebase from 'firebase/app'
import Message from './sync/message'
import MessageThread from './sync/message_thread'
import RequestPostImageFileOnMessage from './sync/request/request_post_image_file_on_message'
import ResponseMessageThreadInfo from './sync/response/response_message_thread_info'
import User from './sync/user'
import RequestUserSearchCondition from '../net/sync/request/request_user_search_condition'
import PaymentUser from './sync/payment_users'
import ResponseFavoriteUserInfo from './sync/response/response_favorite_user_info'
import RequestSignUpInfo from './sync/request/request_signup_info'
import { stringify } from 'flatted'
import ResponseMessageInfo from './sync/response/response_message_info'
import RequestEditProfile from './sync/request/request_edit_profile'
import UserNotificationSetting from './sync/user_notification_setting'
import store from '@/store/store'
import { showErrorDialog } from '@/store/mutation_types'

interface ApiManagerResponse<T> {
  data?: AxiosResponse<T>
  error?: AxiosError<any>
}

class ApiManager {
  jsonAxiosInstance?: AxiosInstance
  formDataAxiosInstance?: AxiosInstance

  init = async (): Promise<void> => {
    this.jsonAxiosInstance = axios.create({
      baseURL: process.env.VUE_APP_API_BASE_URL,
      withCredentials: true,
      timeout: 10000,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    this.jsonAxiosInstance?.interceptors.request.use(
      async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
        const idToken = await firebase.auth().currentUser?.getIdToken(false)
        config.headers['Authorization'] = `Bearer ${idToken}`
        return config
      }
    )
    this.jsonAxiosInstance?.interceptors.response.use(
      async (response: AxiosResponse): Promise<AxiosResponse> => {
        return response
      },
      async (error: any) => this.responseFaileds(error)
    )

    this.formDataAxiosInstance = axios.create({
      baseURL: process.env.VUE_APP_API_BASE_URL,
      withCredentials: true,
      timeout: 10000,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    })
    this.formDataAxiosInstance?.interceptors.request.use(
      async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
        const idToken = await firebase.auth().currentUser?.getIdToken(false)
        config.headers['Authorization'] = `Bearer ${idToken}`
        return config
      }
    )
    this.formDataAxiosInstance.interceptors.response.use(
      async (response: AxiosResponse): Promise<AxiosResponse> => {
        return response
      },
      async (error: any) => this.responseFaileds(error)
    )
  }

  private responseFaileds(error: any) {
    // オフラインの時、サーバーが落ちた時、CORSでエラーになった時はここに入る
    const errorMessage = error?.response?.data?.error
    if (errorMessage) {
      store.commit(showErrorDialog, errorMessage)
      throw new Error(errorMessage)
    }
    store.commit(showErrorDialog, 'サーバー通信に失敗しました。')
    throw new Error('サーバー通信に失敗しました。')
  }

  // TODO: 使っている箇所を入れ替える
  async createInstance(test?: string): Promise<AxiosInstance> {
    return this.jsonAxiosInstance!
  }

  private uploadFile = async <T>(
    path: string,
    uploadFile: File,
    requestObject?: any
  ): Promise<ApiManagerResponse<T>> => {
    const idToken = await firebase.auth().currentUser?.getIdToken(false)
    const instance = await this.createInstance()

    try {
      const formData = new FormData()
      formData.append('uploadFile', uploadFile)

      // アップロードファイル以外はJSON文字列にして'requestObject'に格納する
      if (requestObject) {
        const requestObjectJsonString = stringify(requestObject)
        console.log(`requestObjectJsonString: ${requestObjectJsonString}`)
        formData.append('requestObject', requestObjectJsonString)
      }

      // FIXME: timeoutの見直し
      const response = await instance.post<T>(path, formData)
      return { data: response }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return { error: err }
      }
      throw err
    }
  }

  // LP実装されたら消す
  async sendSignupUrlByMail(email: string): Promise<void> {
    const instance = await this.createInstance()
    await instance.post('send-signup-url-by-mail', { email: email })
  }

  async singup(signupInfo: RequestSignUpInfo): Promise<string> {
    const instance = await this.createInstance()
    const response = await instance.post('signup', { signup_info: signupInfo })
    return response.data
  }

  async requestChangeEmail(email: string): Promise<void> {
    const instance = await this.createInstance()
    await instance.post('/me/email/request', {
      email: email,
    })
  }

  async confirmChangeEmail(confirmationCode: string): Promise<void> {
    const instance = await this.createInstance()
    await instance.post('/me/email/confirm', {
      confirmationCode: confirmationCode,
    })
  }

  async getUser(user_id: string): Promise<User> {
    const instance = await this.createInstance()
    const response = await instance.get('/users/' + user_id)
    return response.data
  }

  // TODO: meと合体で良さげ
  async getPaymentUser(): Promise<PaymentUser> {
    const instance = await this.createInstance()
    const response = await instance.get('/users/payment')
    return response.data
  }

  async getMe(): Promise<User> {
    const instance = await this.createInstance()
    const response = await instance.get('/users/me')
    return response.data
  }

  async getNotificationSetting(): Promise<UserNotificationSetting> {
    const instance = await this.createInstance()
    const response = await instance.get('/users/notification-setting')
    return response.data
  }

  async postNotificationSetting(
    setting: UserNotificationSetting
  ): Promise<UserNotificationSetting> {
    const instance = await this.createInstance()
    const response = await instance.post('/users/notification-setting', {
      params: setting,
    })
    return response.data
  }

  async postMyProfile(req: RequestEditProfile): Promise<void> {
    const instance = await this.createInstance()
    await instance.post('/users/me/profile', {
      params: req,
    })
  }

  async searchUser(condition: RequestUserSearchCondition): Promise<User[]> {
    const instance = await this.createInstance()
    const response = await instance.get('/users/search', {
      params: condition,
    })
    return response.data
  }

  async getMessageThreadList(
    page: number
  ): Promise<ResponseMessageThreadInfo[]> {
    const instance = await this.createInstance()
    // FIXME: デバッグ用にベタ書きしているのを消す
    const response = await instance.get<ResponseMessageThreadInfo[]>(
      '/message-thread',
      {
        params: { page: page },
      }
    )
    return response.data
  }

  async getMessageThread(messageThreadId: string): Promise<MessageThread> {
    const instance = await this.createInstance()
    const response = await instance.get<MessageThread>(
      '/message-thread/' + messageThreadId
    )
    return response.data
  }

  async getMessages(
    messageThreadId: string,
    oldestSendDateTime: Date | undefined
  ): Promise<ResponseMessageInfo> {
    const params =
      oldestSendDateTime !== undefined
        ? {
            oldest_send_datetime_ios: new Date(
              oldestSendDateTime
            ).toISOString(),
          }
        : undefined

    const instance = await this.createInstance()
    const response = await instance.get<ResponseMessageInfo>(
      '/message-thread/' + messageThreadId + '/message',
      {
        params: params,
      }
    )
    return response.data
  }

  async postMessage(message: Message): Promise<void> {
    const instance = await this.createInstance()
    const response = await instance.post(
      '/message-thread/' + message.messageThreadId + '/message',
      { message: message }
    )
    return response.data
  }

  async postUserMainImage(imageFile: File): Promise<ApiManagerResponse<void>> {
    return await this.uploadFile<void>('/users/image/main', imageFile)
  }

  async postUserSubImage(imageFile: File): Promise<ApiManagerResponse<void>> {
    return await this.uploadFile<void>('/users/image/sub', imageFile)
  }

  async deleteUserSubImage(userProfileImageId: string): Promise<void> {
    const instance = await this.createInstance()
    await instance.post('/users/image/delete', {
      userProfileImageId: userProfileImageId,
    })
  }

  async postImage(
    messageThreadId: string,
    imageFile: File,
    req: RequestPostImageFileOnMessage
  ): Promise<ApiManagerResponse<void>> {
    return await this.uploadFile<void>(
      '/message-thread/' + messageThreadId + '/image',
      imageFile,
      req
    )
  }

  async postMessageHasRead(messageThreadId: string): Promise<void> {
    const instance = await this.createInstance()
    const response = await instance.post(
      `/message-thread/${messageThreadId}/message/read`
    )
    return response.data
  }

  async leavePayment(): Promise<void> {
    const instance = await this.createInstance()
    const response = await instance.post('/auth/credit/leave')
  }

  async getFavorites(page: number): Promise<ResponseFavoriteUserInfo[]> {
    const instance = await this.createInstance()
    const response = await instance.get('/favorite/users', {
      params: { page: page },
    })
    return response.data
  }

  async postRegisterFavorite(userId: string): Promise<void> {
    const instance = await this.createInstance()
    await instance.post('/favorite/register', { to_user_id: userId })
  }

  async postUserCertificationImage(
    file: File
  ): Promise<ApiManagerResponse<void>> {
    return await this.uploadFile<void>('/certification/image', file)
  }

  async postTransformBlur(file: File): Promise<ArrayBuffer> {
    const instance = await this.createInstance('multipart/form-data')
    const formData = new FormData()
    formData.append('uploadFile', file)
    const response = await instance.post<ArrayBuffer>(
      '/image/transformation/blur',
      formData,
      {
        responseType: 'arraybuffer',
      }
    )
    return response.data
  }

  async getTest(): Promise<void> {
    const instance = await this.createInstance()
    const response = await instance.get('/test/error')
    return response.data
  }
}

export default new ApiManager()
