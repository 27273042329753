














































import { Component, Vue } from 'vue-property-decorator'
import ApiManager from '../net/api-manager'
import InfiniteLoading from 'vue-infinite-loading'
import ResponseFavoriteUserInfo from '@/net/sync/response/response_favorite_user_info'
import MatchingDialog from '../components/organisms/MatchingDialog.vue'
import apiManager from '../net/api-manager'
import SexType from '@/net/sync/types/sex_type'
import * as types from '@/store/mutation_types'
import store from '@/store/store'
import AvatorImageUtil from '@/util/avator_image_util'
import DownloadImageUtil from '../util/download_image_util'

interface FavoriteInfo {
  favorite: ResponseFavoriteUserInfo
  avatarUrl: string
  profileUrl: string
}

@Component({
  components: {
    InfiniteLoading,
    MatchingDialog,
  },
})
export default class FavoritReceipt extends Vue {
  favorites: FavoriteInfo[] = [] // firebaseから画像のURLを取得するため、APIのレスポンスをこちらに詰め替える
  page = 1
  infiniteId = +new Date()
  isShowMatchingDialog = false

  defaultManImage = require('@/assets/images/man_no_image.jpg')
  defaultWomanImage = require('@/assets/images/woman_no_image.jpg')

  myImage = ''
  otherImage = ''

  async infiniteHandler($state: any) {
    const res = await ApiManager.getFavorites(this.page)
    if (res.length) {
      const favorites = await Promise.all(
        res.map(async (f) => {
          let avatarImage = ''
          let profileImage = ''
          if (f.avatarImageUrl) {
            avatarImage = await DownloadImageUtil.getImageUrl(f.avatarImageUrl)
          }
          if (f.mainImageUrl) {
            profileImage = await DownloadImageUtil.getImageUrl(f.mainImageUrl)
          }
          return {
            favorite: f,
            avatarUrl: avatarImage,
            profileUrl: profileImage,
          }
        })
      )
      this.page += 1
      this.favorites.push(...favorites)
      $state.loaded()
    } else {
      $state.complete()
    }
  }

  async register(userId: string) {
    store.commit(types.showIndicator)
    await apiManager.postRegisterFavorite(userId)
    this.otherImage = this.defaultWomanImage
    const myUser = await apiManager.getMe()
    const otherUser = await apiManager.getUser(userId)
    this.myImage = await AvatorImageUtil.getAvatorImageUrl(
      myUser.sexType,
      myUser.avatarImageUrl
    )
    this.otherImage = await AvatorImageUtil.getAvatorImageUrl(
      otherUser.sexType,
      otherUser.avatarImageUrl
    )
    this.isShowMatchingDialog = true
    this.reload()
    store.commit(types.closeIndicator)
  }

  reload(): void {
    this.favorites = []
    this.infiniteId += 1
  }

  toUserProfile(userId: string) {
    this.$router.push(`profile/other/${userId}`)
  }
}
