import firebase from 'firebase/app'
import { State } from './state'

export default {
  isSignin: () => (): boolean => {
    // firebaseでキャッシュしてるのでvuexに持ち込むのは微妙だが、判断を共通化したいためこちらに寄せる
    return firebase.auth().currentUser !== null
  },
  searchConditionStr: (state: State) => () => {
    return state.searchCondition
  },
}
