
















import { Component, Vue } from 'vue-property-decorator'
import { Mutation, State } from 'vuex-class'
import * as types from '@/store/mutation_types'

@Component
export default class InformationDialog extends Vue {
  @State('isShowInformationDialog')
  private readonly isShowInformationDialog!: boolean
  @State('informationMessage') private readonly informationMessage?: string
  @Mutation(types.closeInformationDialog) closeInformationDialog!: () => void

  get message(): string {
    return this.informationMessage ?? ''
  }
}
