



















import apiManager from '@/net/api-manager'
import UserNotificationSetting from '@/net/sync/user_notification_setting'
import { Component, Vue } from 'vue-property-decorator'
import * as types from '@/store/mutation_types'
import store from '@/store/store'

@Component({
  components: {},
})
export default class NotificationSetting extends Vue {
  isReceivedFavorite = false
  isReceivedMessage = false

  setting?: UserNotificationSetting

  async mounted(): Promise<void> {
    store.commit(types.showIndicator)
    this.setting = await apiManager.getNotificationSetting()
    this.isReceivedFavorite = this.setting.isReceivedFavorite
    this.isReceivedMessage = this.setting.isReceivedMessage
    store.commit(types.closeIndicator)
  }

  async update(): Promise<void> {
    if (!this.setting) {
      return
    }
    store.commit(types.showIndicator)
    this.setting.isReceivedFavorite = this.isReceivedFavorite
    this.setting.isReceivedMessage = this.isReceivedMessage
    await apiManager.postNotificationSetting(this.setting)
    store.commit(types.closeIndicator)
  }
}
