

























































































































import RequestSignUpInfo from '@/net/sync/request/request_signup_info'
import AREA_TYPE from '@/net/sync/types/area_type'
import DRINK_FREQUENCY_TYPE from '@/net/sync/types/drink_frequency_type'
import SMOKING_FREQUENCY_TYPE from '@/net/sync/types/smoking_frequency_type'
import STYLE_TYPE from '@/net/sync/types/style_type'
import { Component, Emit, Vue, Watch } from 'vue-property-decorator'
import * as types from '@/store/mutation_types'
import store from '@/store/store'

@Component({
  components: {},
})
export default class AccountRegisterFrom extends Vue {
  signupInfo: RequestSignUpInfo = {
    userInvitationId: '',
    password: '',
    name: '',
    age: 0,
    height: 130,
    areaType: '',
    sexType: 0,
    introduction: 'よろしくお願いします。',
  }
  sexTypeStr = ''
  passwordConfirm = ''
  isShowPassword = false
  isShowPasswordConfirm = false
  ageList = Array.from(new Array(70)).map((v, i) => i + 20)
  heightList = Array.from(new Array(90)).map((v, i) => i + 130)

  get sexType(): Array<string> {
    return ['男性', '女性']
  }

  get areaType(): Array<string> {
    return Object.keys(AREA_TYPE).filter((s) => s !== AREA_TYPE.NONE)
  }

  get styleType(): Array<string> {
    return Object.keys(STYLE_TYPE).filter((s) => s !== STYLE_TYPE.NONE)
  }

  get drinkFrequencyType(): Array<string> {
    return Object.keys(DRINK_FREQUENCY_TYPE).filter(
      (d) => d !== DRINK_FREQUENCY_TYPE.NONE
    )
  }

  get smokingFrequencyType(): Array<string> {
    return Object.keys(SMOKING_FREQUENCY_TYPE).filter(
      (s) => s !== SMOKING_FREQUENCY_TYPE.NONE
    )
  }

  // Firebaseのパスワードは六文字以上の英数字ならOK
  validatePassword(value: string): boolean {
    const pattern = '(?=.*[0-9a-zA-Z]).{6,}'
    const regex = new RegExp(pattern)
    return regex.test(value)
  }

  @Emit()
  submit() {
    this.signupInfo.userInvitationId = this.$route.params.user_invitation_id
    // TODO: validate
    if (!this.signupInfo.password) {
      store.commit(
        types.showInformationDialog,
        'パスワードを入力してください。'
      )
      return null
    }
    if (!this.validatePassword(this.signupInfo.password)) {
      store.commit(
        types.showInformationDialog,
        'パスワードは6文字以上の英数字を入力してください。'
      )
      return null
    }
    if (this.passwordConfirm !== this.signupInfo.password) {
      store.commit(
        types.showInformationDialog,
        'パスワードと確認用パスワードが一致しません。'
      )
      return null
    }
    if (!this.signupInfo.name) {
      store.commit(
        types.showInformationDialog,
        'ニックネームを入力してください。'
      )
      return null
    }
    if (!this.sexTypeStr) {
      store.commit(types.showInformationDialog, '性別を入力してください。')
      return null
    }
    if (!this.signupInfo.age) {
      store.commit(types.showInformationDialog, '年齢を入力してください。')
      return null
    }
    if (!this.signupInfo.areaType) {
      store.commit(types.showInformationDialog, '居住地を選択してください。')
      return null
    }
    if (
      this.signupInfo.introduction?.length &&
      this.signupInfo.introduction?.length > 200
    ) {
      store.commit(
        types.showInformationDialog,
        '自己紹介文は200文字以内で入力してください。'
      )
      return null
    }
    this.signupInfo.sexType = this.sexTypeStr === '男性' ? 0 : 1
    return this.signupInfo
  }

  todofuken = [
    '北海道',
    '青森県',
    '岩手県',
    '宮城県',
    '秋田県',
    '山形県',
    '福島県',
    '茨城県',
    '栃木県',
    '群馬県',
    '埼玉県',
    '千葉県',
    '東京都',
    '神奈川県',
    '新潟県',
    '富山県',
    '石川県',
    '福井県',
    '山梨県',
    '長野県',
    '岐阜県',
    '静岡県',
    '愛知県',
    '三重県',
    '滋賀県',
    '京都府',
    '大阪府',
    '兵庫県',
    '奈良県',
    '和歌山県',
    '鳥取県',
    '島根県',
    '岡山県',
    '広島県',
    '山口県',
    '徳島県',
    '香川県',
    '愛媛県',
    '高知県',
    '福岡県',
    '佐賀県',
    '長崎県',
    '熊本県',
    '大分県',
    '宮崎県',
    '鹿児島県',
    '沖縄県',
  ]
}
