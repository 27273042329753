







































































import firebase from 'firebase'
import { Component, Vue } from 'vue-property-decorator'
import ApiManager from '../net/api-manager'
import MessageThreadInfo from '../net/sync/response/response_message_thread_info'
import AgeCertificateDialog from '../components/AgeCertificateDialog.vue'
import '../util/common_extension'
import InfiniteLoading from 'vue-infinite-loading'
import AvatarImageUtil from '../util/avator_image_util'
import * as types from '@/store/mutation_types'
import store from '@/store/store'

interface MessageThreadDisplay {
  thread: MessageThreadInfo
  imageUrl: string
}

@Component({
  components: { AgeCertificateDialog, InfiniteLoading },
})
export default class MessageThreadListPage extends Vue {
  infiniteId = +new Date()
  page = 1

  messageThreads: MessageThreadDisplay[] = []
  isAgeCertified = false
  isCheckedMessage = false // クリックしたら年齢確認ダイアログを表示する

  async mounted(): Promise<void> {
    store.commit(types.showIndicator)
    const myUserId = firebase.auth().currentUser?.uid
    if (!myUserId) {
      return
    }

    const me = await ApiManager.getUser(myUserId)
    if (me) {
      this.isAgeCertified = me.isAgeCertified
    }
    store.commit(types.closeIndicator)
  }

  get isShowAgeCertificateDialog(): boolean {
    return this.isCheckedMessage
  }

  get isShowMessageThreadList(): boolean {
    return this.messageThreads.length > 0
  }

  async infiniteHandler($state: any) {
    const res = await ApiManager.getMessageThreadList(this.page)
    if (res.length) {
      this.page += 1

      const messageThreads = await Promise.all(
        res.map(async (thread) => {
          const url = await AvatarImageUtil.getAvatorImageUrl(
            thread.partner_sex_type,
            thread.partner_image_url
          )

          return {
            thread: thread,
            imageUrl: url,
          }
        })
      )
      this.messageThreads.push(...messageThreads)
      $state.loaded()
    } else {
      $state.complete()
    }
  }

  clickMessageThreadCard(messageThreadId: string): void {
    if (!this.isAgeCertified) {
      this.isCheckedMessage = true
      return
    }
    this.$router.push(this.getMessageThreadPage(messageThreadId))
  }

  getMessageThreadPage(message_thread_id: string): string {
    return '/message-thread/' + message_thread_id
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get messageThreadItems(): MessageThreadDisplay[] {
    return this.messageThreads
  }

  getLatestMessageArrivalLabel(fromStrings: string): string {
    const diffDays = this.getFromNumberDays(fromStrings)
    if (diffDays == 0) {
      return '本日'
    } else {
      return diffDays + '日前'
    }
  }

  getFromNumberDays(fromStrings: string): number {
    const now = new Date()
    const from = new Date(fromStrings)
    const diffDays = (now.getUnixTime() - from.getUnixTime()) / (60 * 60 * 24)
    return Math.floor(diffDays)
  }
}
