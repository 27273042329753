









































































































import AREA_TYPE from '@/net/sync/types/area_type'
import DRINK_FREQUENCY_TYPE from '@/net/sync/types/drink_frequency_type'
import SMOKING_FREQUENCY_TYPE from '@/net/sync/types/smoking_frequency_type'
import STYLE_TYPE from '@/net/sync/types/style_type'
import { Component, Emit, Vue, Watch } from 'vue-property-decorator'
import { Mutation, State } from 'vuex-class'
import RequestUserSearchCondition from '../net/sync/request/request_user_search_condition'
import AgeSelectType from '@/model/types/age-select-type'
import HeightSelectType from '@/model/types/height-select-type'

@Component
export default class SearchDialog extends Vue {
  @State('showSearchDialog') private readonly showSearchDialog!: boolean
  @Mutation('setShowSearchDialog') setShowSearchDialog!: (
    showSearchDialog: boolean
  ) => void
  @State('searchCondition')
  private readonly searchCondition!: RequestUserSearchCondition
  @Mutation('setSearchCondition') setSearchCondition!: (
    searchCondition: RequestUserSearchCondition
  ) => void
  @Mutation('clearSearchCondition') clearSearchCondition!: () => void

  get refs(): any {
    return this.$refs
  }

  condition: RequestUserSearchCondition = {
    areaType: undefined,
    ageFrom: undefined,
    ageTo: undefined,
    heightFrom: undefined,
    heightTo: undefined,
    styleType: undefined,
    drinkFrequencyType: undefined,
    smokingFrequencyType: undefined,
    page: 0,
  }

  ageTypeName = ''
  heightTypeName = ''

  get areaType(): Array<string> {
    return Object.keys(AREA_TYPE).filter((a) => a !== AREA_TYPE.NONE)
  }

  get ageType(): Array<string> {
    return Object.values(AgeSelectType).map((a) => a.name)
  }

  get heightType(): Array<string> {
    return Object.values(HeightSelectType).map((h) => h.name)
  }

  get styleType(): Array<string> {
    return Object.keys(STYLE_TYPE).filter((s) => s !== STYLE_TYPE.NONE)
  }

  get drinkFrequencyType(): Array<string> {
    return Object.keys(DRINK_FREQUENCY_TYPE).filter(
      (d) => d !== DRINK_FREQUENCY_TYPE.NONE
    )
  }

  get smokingFrequencyType(): Array<string> {
    return Object.keys(SMOKING_FREQUENCY_TYPE).filter(
      (s) => s !== SMOKING_FREQUENCY_TYPE.NONE
    )
  }

  @Watch('showSearchDialog', { immediate: true })
  onShowSearchDialog() {
    // ダイアログが開かれた時のライフサイクルが取れないため、しょうがなくここで
    if (!this.showSearchDialog) return
    this.condition = this.searchCondition
    if (this.condition.ageFrom || this.condition.ageTo) {
      const name = Object.values(AgeSelectType).find(
        (a) =>
          a.ageFrom === this.condition.ageFrom &&
          a.ageTo === this.condition.ageTo
      )?.name
      this.ageTypeName = name ?? ''
    }
    if (this.condition.heightFrom || this.condition.heightTo) {
      const name = Object.values(HeightSelectType).find(
        (h) =>
          h.heightFrom === this.condition.heightFrom &&
          h.heightTo === this.condition.heightTo
      )?.name
      this.heightTypeName = name ?? ''
    }
  }

  @Emit('onClickSearch')
  onClickSearch(): RequestUserSearchCondition {
    if (this.ageTypeName !== '') {
      const ageType = Object.values(AgeSelectType).find(
        (a) => a.name === this.ageTypeName
      )!
      this.condition.ageFrom = ageType.ageFrom
      this.condition.ageTo = ageType.ageTo
    }
    if (this.heightTypeName !== '') {
      const heightType = Object.values(HeightSelectType).find(
        (h) => h.name === this.heightTypeName
      )!
      this.condition.heightFrom = heightType.heightFrom
      this.condition.heightTo = heightType.heightTo
    }
    this.setSearchCondition(this.condition)
    return this.condition
  }

  @Emit('onClickClear')
  onClickClear(): void {
    this.ageTypeName = ''
    this.heightTypeName = ''
    this.condition = {
      areaType: undefined,
      ageFrom: undefined,
      ageTo: undefined,
      heightFrom: undefined,
      heightTo: undefined,
      styleType: undefined,
      drinkFrequencyType: undefined,
      smokingFrequencyType: undefined,
      page: 0,
    }
    this.clearSearchCondition()
    return
  }

  // iPhoneでダイアログが変なスクロールしてしまう件の対応策
  async offFocused() {
    this.refs.vselect1.blur()
    this.refs.vselect2.blur()
    this.refs.vselect3.blur()
    this.refs.vselect4.blur()
    this.refs.vselect5.blur()
    this.refs.vselect6.blur()
  }
}
