


















































import { Component, Vue, Prop, PropSync, Emit } from 'vue-property-decorator'

@Component
export default class MatchingDialog extends Vue {
  @PropSync('isShowDialog', { type: Boolean, default: false })
  showStatus!: boolean

  @Prop({ type: String, default: '' })
  myImageUrl: string | undefined

  @Prop({ type: String, default: '' })
  otherImageUrl: string | undefined

  toMessageThreadList() {
    this.$router.push('/message-thread')
  }
}
